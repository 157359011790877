import {Suspense} from "react"
import LoadingPage from "@ui/components/LoadingPage"
import {AppLazy} from "@ui/bootLazy"
import {createRoot} from "react-dom/client"
import "@shared/loading"

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import {registerSW} from "virtual:pwa-register"

registerSW({immediate: true})

const content = <Suspense fallback={<LoadingPage/>}><AppLazy/></Suspense>

window.addEventListener("unhandledrejection", event => {
  if (event.reason === "Request cancelled") {
    event.preventDefault()
    event.stopImmediatePropagation()
    event.stopPropagation()
    return false
  }
})

window.addEventListener("error", (event) => {
  const eventError = event.error
  let error
  if (eventError instanceof Error) {
    error = {
      name: eventError.name,
      message: eventError.message,
      stack: eventError.stack,
      cause: eventError.cause || null
    }
  } else {
    // get type name/constructor of error
    error = {
      name: typeof eventError,
      message: JSON.stringify(eventError),
      stack: "Unknown",
      cause: "Unknown"
    }
  }
  fetch("/api/error/log", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      browserUrl: window.location.href,
      message: event.message,
      source: event.filename,
      lineno: event.lineno,
      colno: event.colno,
      error: error
    })
  }).then(() => {
    console.log("Logged error")
  }, () => {
    console.error("Failed to log error")
  })
})

// Inject our app into the DOM
const mountElement = document.getElementById("app")
if (!mountElement) {
  throw new Error("No mount element found")
} else {
  createRoot(mountElement).render(content)
}

